const productPriceWatcher = (e) => {

  const subscriptionBlock = document.querySelector("fieldset.subscription-options");
  const labelList = document.querySelector(".product-meta__label-list");
  const compareAtPrice = document.querySelector(".product__info.product-subscription .product-meta__price-list-container .price--compare");

  if (subscriptionBlock.classList.contains('subscription-options--hidden') || !compareAtPrice) {
    labelList.classList.add('visually-hidden');
  } else {
    labelList.classList.remove('visually-hidden');
  }

  document.addEventListener("variant:changed", (evt) => {    
    if (subscriptionBlock.classList.contains('subscription-options--hidden') || !compareAtPrice) {
      labelList.classList.add('visually-hidden');
    } else {
      labelList.classList.remove('visually-hidden');
    }
  });
}

export default productPriceWatcher;